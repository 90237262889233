<template>
  <div id="event-list">
    <v-card>
      <v-card-title>
        Events list
        <v-spacer></v-spacer>
        <v-btn color="primary" class="me-3 export-to-csv-button" @click="exportEvents()"> Export to CSV </v-btn>
        <v-btn :color="isMassSelectedEvents ? 'success' : 'primary'" class="me-3 mass-select-button mr-5" @click="massSelectEvents()"> Mass Select</v-btn>
        <v-btn v-if="selectedEvents.length" color="primary" class="me-3 mass-edit-button" @click="bulkUpdateEventsStatusDialog = true"> Mass Update </v-btn>
        <v-btn v-if="isAdmin" color="warning" class="me-3 upload-file-button" @click="uploadFileDialog = true"> Upload file </v-btn>
        <v-btn v-if="isAdmin" color="warning" class="me-3 generate-fake-event-button" @click="generateFakeEventDialog = true"> Generate fake events </v-btn>
      </v-card-title>

      <v-row class="px-2 ma-0">
        <v-col v-if="isAdmin" cols="12" sm="3">
          <v-autocomplete
            class="chargeback-event-company-filter"
            v-model="companyFilter"
            @change="saveFilters"
            :items="merchants"
            item-value="companyId"
            item-text="accountName"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Merchant name"
            multiple
            chips
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            class="chargeback-event-website-filter"
            v-model="websiteFilter"
            @change="saveFilters"
            :items="formattedWebsites"
            item-value="id"
            item-text="name"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Website name"
            multiple
            chips
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            class="chargeback-event-service-filter"
            v-model="serviceFilter"
            @change="saveFilters"
            placeholder="Select Service"
            :items="serviceOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            class="chargeback-event-card-type-filter"
            v-model="cardTypeFilter"
            @change="saveFilters"
            placeholder="Select Card type"
            :items="cardTypeOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            class="chargeback-event-status-filter"
            v-model="statusFilter"
            @change="saveFilters"
            placeholder="Select status"
            :items="chargebackStatusOptions"
            item-text="title"
            item-value="value"
            outlined
            multiple
            dense
            :disabled="!!isMassSelectedEvents"
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-dialog ref="dialog" v-model="eventDateModal" :return-value.sync="eventDateFilter" width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="eventDateFilter"
                @change="saveFilters"
                class="chargeback-event-date-filter"
                label="Select Event date"
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="eventDateFilter" class="event-date-picker" scrollable range>
              <v-row>
                <v-col cols="12">
                  <v-select
                    placeholder="Select presets"
                    :items="presetsForEventDatePicker"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                    clearable
                    hide-details
                    @change="selectedEventPreset"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row style="text-align: end">
                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save((eventDateFilter = null))"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(eventDateFilter); saveFilters()"> OK </v-btn>
                </v-col>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn color="secondary" outlined type="reset" :disabled="!!isMassSelectedEvents" @click="resetFilters"> Reset parameters </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-4"></v-divider>

      <v-row>
        <v-col cols="6">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="searchQuery"
              @change="saveFilters"
              @keyup.delete="searchKeyupDelete"
              placeholder="Please enter 4 or more characters"
              outlined
              hide-details
              dense
              class="chargeback-event-search-query user-search me-3 mb-4"
            >
            </v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <v-autocomplete
            v-model="headersSelected"
            @change="saveFilters"
            outlined
            dense
            hide-details
            :items="tableColumns"
            return-object
            multiple
            placeholder="Table columns setting"
            class="chargeback-event-table-col-settings user-search me-3 mb-4"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <!-- table -->
      <v-data-table
        v-model="selectedEvents"
        :options.sync="options"
        :headers="headersSelected"
        :items="eventListTable"
        :server-items-length="totalEventListTable"
        :loading="loading"
        :item-class="highlightsTableRow"
        :footer-props="{ 'items-per-page-options': [50, 75, 100] }"
        :custom-sort="customSort"
        :show-select="isMassSelectedEvents"
      >
        <template v-for="header in headersSelected" #[`header.${header.value}`]="{}">
          <span tabindex="0" :key="header.order">
            {{ header.text }}
            <v-tooltip v-if="header.tooltip" bottom>
              <template #activator="{ on }">
                <v-icon small v-on="on">{{ icons.mdiHelpCircleOutline }}</v-icon>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </span>
        </template>

        <template #[`item.createdAt`]="{ item }">
          <div class="chargeback-event-item-created-at" style="width: 90px">{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</div>
        </template>

        <template #[`item.accountName`]="{ item }">
          <div class="chargeback-event-item-account-name">{{ item.accountName }}</div>
        </template>

        <template #[`item.websiteName`]="{ item }">
          <div class="chargeback-event-item-website">{{ item.websiteName }}</div>
        </template>
        <template #[`item.details`]="{ item }">
          <div class="chargeback-event-item-website">
            <div v-if="item.service === eventServiceEnum.MASTERCARD_ALERT || item.service === eventServiceEnum.VISA_CDRN" title="descriptor">
               {{ item.merchantDescriptor }}
            </div>
            <div v-else>
              <div title="bin"> {{ item.bin }} </div>
              <div title="caid"> {{ item.caid }} </div>
            </div>
          </div>
        </template>
        <template #[`item.cardType`]="{ item }">
          <div style="text-align: center">
            <img
              :src="require(`@/assets/images/svg/${item.cardType}-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
              height="30px"
              width="40px"
              class="me-2 v-image__image--contain"
              alt="Card type logo">
          </div>
        </template>

        <template #[`item.cardNumber`]="{ item }">
          <div class="chargeback-event-item-card-number">{{ item.cardNumber }}</div>
        </template>

        <template #[`item.arn`]="{ item }">
          <div class="chargeback-event-item-arn">{{ item.arn }}</div>
        </template>

        <template #[`item.alertTimestamp`]="{ item }">
          <div style="width: 90px">{{ item.alertTimestamp | moment(`${getDateFormat(item.alertTimestamp)}`) }}</div>
        </template>

        <template #[`item.transactionAmount`]="{ item }">
          <div style="width: 80px">{{ item.transactionAmount }}</div>
        </template>

        <template #[`item.transactionDate`]="{ item }">
          <div style="width: 90px">{{ item.transactionDate | moment(`${getDateFormat(item.transactionDate)}`) }}</div>
        </template>

        <template #[`item.status`]="{ item }">
          <div v-if="canChangeStatus(item)">
            <v-edit-dialog large persistent @save="updateStatus(item.id, newEventStatus, refunded)">
              <v-chip
                small
                :color="resolveEventStatusVariant(item.status)"
                :class="`${resolveEventStatusVariant(item.status)}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize pointer"
              >
                {{ item.status }}
              </v-chip>
              <template #input>
                <div class="mt-4 title">Update Status</div>
                <v-select class="event-status"
                  v-model="newEventStatus"
                  :items="chargebackOutcomeStatusOptions"
                  item-text="title"
                  item-value="value"
                  label="Status"
                  placeholder="Event Status"
                ></v-select>

                <v-select class="refunded-status"
                  v-model="refunded"
                  :items="chargebackOutcomeRefundStatusOptions"
                  item-text="title"
                  item-value="value"
                  label="Refunded status"
                >
                </v-select>
              </template>
            </v-edit-dialog>
          </div>
          <div v-else>
            <v-chip
              small
              :color="resolveEventStatusVariant(item.status)"
              :class="`${resolveEventStatusVariant(item.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status }}
            </v-chip>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="chargeback-event-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="chargeback-event-list-item-detail" :to="{ name: 'event-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="outcomeDialog" persistent max-width="290">
      <v-card v-if="!isUpdatedStatusReceived" color="primary" dark>
        <v-card-text>
          Updating Event status. Please wait...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card class="modal-outcome" v-else>
        <v-card-title></v-card-title>
        <v-card-text>{{ updatedStatusResponse }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class='submit' color="green darken-1" text @click="closeOutcomeDialog"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploadFileDialog" max-width="990">
      <v-card class="file-upload-modal">
        <v-card-title>File upload</v-card-title>
        <v-card-text>
          <v-alert v-if="uploadedFileProcessingResult" color="success" dark>
            {{ uploadedFileProcessingResult }}
          </v-alert>
          <v-alert v-if="uploadedFileProcessingError" color="error" dark>
            {{ uploadedFileProcessingError }}
          </v-alert>
          <v-alert border="left" colored-border text color="warning">
            <p class="mb-0">
              Attention: Please delete all selective cells from your file! Rows with selective cells can not be
              imported!
            </p>
            <p class="mb-0">You can only select a file with .xlsx extension.</p>
            <p class="mb-0">Upload will start automatically after file selection.</p>
          </v-alert>
          <v-file-input
            v-model="uploadedFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            label="Select a file..."
            :loading="uploadedFileProcessing"
            :disabled="uploadedFileProcessing"
            show-size
            outlined
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" outlined @click="uploadFileDialog = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <generate-fake-events-modal :websites="websites" :is-open="generateFakeEventDialog" @closeFakeEventsDialog="closeFakeEventsDialog"></generate-fake-events-modal>
    <bulk-update-events-status-modal :events="selectedEvents" :is-open="bulkUpdateEventsStatusDialog" @closeBulkUpdateEventsStatusDialogDialog="closeBulkUpdateEventsStatusDialogDialog"></bulk-update-events-status-modal>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFileDocumentOutline, mdiHelpCircleOutline } from '@mdi/js';
import { getDateFormat } from '@core/utils/filter';
import { getItemWithTtl } from '@core/utils/local-storage-with-ttl';
import useChargebackEventsList from '@/views/chargebacks/chargeback-events/useChargebackEventsList';
import GenerateFakeEventsModal
  from '@/views/chargebacks/chargeback-events/componnents/GenerateFakeEventsModal';
import BulkUpdateEventsStatusModal
  from '@/views/chargebacks/chargeback-events/componnents/BulkUpdateEventsStatusModal.vue';

export default {
  components: { BulkUpdateEventsStatusModal, GenerateFakeEventsModal },
  setup() {
    const {
      searchQuery,
      eventListTable,
      tableColumns,
      loading,
      options,
      eventTotalLocal,
      totalEventListTable,
      newEventStatus,
      fetchEvents,
      resolveEventStatusVariant,
      merchants,
      websites,
      companyFilter,
      serviceFilter,
      cardTypeFilter,
      websiteFilter,
      statusFilter,
      headersSelected,
      outcomeDialog,
      updatedStatusResponse,
      isUpdatedStatusReceived,
      serviceOptions,
      eventServiceEnum,
      cardTypeOptions,
      chargebackStatusOptions,
      chargebackOutcomeStatusOptions,
      chargebackOutcomeRefundStatusOptions,
      eventTypeEnum,
      eventType,
      updateStatus,
      closeOutcomeDialog,
      isAdmin,
      eventDateFilter,
      eventDateModal,
      refunded,
      presetsForEventDatePicker,
      exportEvents,
      generateFakeEventDialog,
      uploadFileDialog,
      uploadedFile,
      uploadedFileProcessing,
      uploadedFileProcessingResult,
      uploadedFileProcessingError,
      defaultOptions,
      canChangeStatus,
      isMassSelectedEvents,
      chargebackStatusEnum,
      resetFilters,
      saveFilters,
      searchKeyupDelete,
      selectedEvents,
      bulkUpdateEventsStatusDialog,
    } = useChargebackEventsList();

    const highlightsTableRow = item => {
      const defaultClass = 'chargeback-event-item';
      if (item.status === 'pending') {
        return `${defaultClass} pending-row`;
      } else if (item.status === 'expired') {
        return `${defaultClass} expired-row`;
      }
      return defaultClass;
    };

    const customSort = items => {
      return items;
    };

    const closeFakeEventsDialog = () => {
      fetchEvents();
      generateFakeEventDialog.value = false;
    };

    const closeBulkUpdateEventsStatusDialogDialog = () => {
      resetFilters();
      bulkUpdateEventsStatusDialog.value = false;
      isMassSelectedEvents.value = false;
      selectedEvents.value = [];
    };

    const massSelectEvents = () => {
      resetFilters();
      isMassSelectedEvents.value = !isMassSelectedEvents.value;
      if (isMassSelectedEvents.value) {
        statusFilter.value = chargebackStatusEnum.PENDING;
      } else {
        selectedEvents.value = [];
      }
    };

    const selectedEventPreset = preset => {
      return (eventDateFilter.value = preset);
    };

    return {
      eventServiceEnum,
      isAdmin,
      headersSelected,
      searchQuery,
      websites,
      websiteFilter,
      merchants,
      companyFilter,
      serviceFilter,
      serviceOptions,
      cardTypeFilter,
      cardTypeOptions,
      eventListTable,
      newEventStatus,
      tableColumns,
      totalEventListTable,
      loading,
      options,
      eventTotalLocal,
      customSort,
      fetchEvents,
      resolveEventStatusVariant,
      updateStatus,
      highlightsTableRow,
      eventTypeEnum,
      eventType,
      refunded,
      outcomeDialog,
      isUpdatedStatusReceived,
      updatedStatusResponse,
      closeOutcomeDialog,
      chargebackStatusOptions,
      chargebackOutcomeStatusOptions,
      chargebackOutcomeRefundStatusOptions,
      statusFilter,
      eventDateFilter,
      eventDateModal,
      presetsForEventDatePicker,
      selectedEventPreset,
      exportEvents,
      getDateFormat,
      generateFakeEventDialog,
      closeFakeEventsDialog,
      uploadFileDialog,
      uploadedFile,
      uploadedFileProcessing,
      uploadedFileProcessingResult,
      uploadedFileProcessingError,
      defaultOptions,
      canChangeStatus,
      massSelectEvents,
      isMassSelectedEvents,
      resetFilters,
      selectedEvents,
      bulkUpdateEventsStatusDialog,
      closeBulkUpdateEventsStatusDialogDialog,
      saveFilters,
      searchKeyupDelete,
      // icons
      icons: {
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiHelpCircleOutline,
      },
    };
  },
  created() {
    const filters = getItemWithTtl('event-filter');
    if (filters) {
      this.companyFilter = filters.companyFilter;
      this.websiteFilter = filters.websiteFilter;
      this.serviceFilter = filters.serviceFilter;
      this.cardTypeFilter = filters.cardTypeFilter;
      this.statusFilter = filters.statusFilter;
      this.eventDateFilter = filters.eventDateFilter;
      this.searchQuery = filters.searchQuery;
    }
  },
  computed: {
    formattedWebsites() {
      return this.websites.map((website) => {
        if (website.status === 'inactive') {
          return { ...website, name: website.name + ' (inactive)' };
        }
        return website;
      });
    },
  },

};
</script>

<style lang="scss">
.pending-row {
  background-color: rgb(255 215 215 / 40%);
}
.expired-row {
  background-color: rgb(201 201 201 / 40%);
}
.event-date-picker {
  > .v-card__actions {
    display: block !important;
    > div.row:last-child {
      text-align: end;
    }
  }
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}
.v-chip-light-bg:not(.pointer):hover:before {
  opacity: 0.12;
}
.v-chip-light-bg.pointer {
  cursor: pointer;
}
@import '@core/preset/preset/apps/user.scss';
</style>
