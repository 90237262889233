<template>
  <v-dialog v-model="massUpdateEventsStatusModal" max-width="370" persistent>
    <div v-if="isLoaded" class="mass-update-events-status-modal">
      <v-card v-if="step === 1">
        <v-form ref="form" v-model="valid" @submit.prevent="validateStatus">
          <v-card-title class="text-center">Update Status to {{events.length}} events</v-card-title>
          <v-card-text>
            <v-select class="event-status"
              v-model="eventStatus"
              :items="chargebackOutcomeStatusOptions"
              :rules="[validators.required]"
              item-text="title"
              item-value="value"
              label="Status"
              placeholder="Event Status"
            ></v-select>
            <v-select class="refunded-status"
              v-model="eventRefunded"
              :items="chargebackOutcomeRefundStatusOptions"
              :rules="[validators.required]"
              item-text="title"
              item-value="value"
              label="Refunded status"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit"> Submit </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="closeModal"> Cancel </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card v-if="step === 2">
        <v-card-title class="text-center">Are you sure you want to change the status of {{events.length}} events?</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="updateEventsStatus"> Yes </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="back"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="step === 3" >
        <v-card-title class="text-center">
          <div class="pt-2 w-full">MasterСard {{result.services.ethoca}} events changed to <br><span class="text-capitalize">{{eventStatus}}</span></div>
          <div class="pt-2 w-full">Visa CDRN {{result.services.cdrn}} events changed to <br><span class="text-capitalize">{{eventStatus}}</span></div>
          <div class="pt-2 w-full">{{result.failed}} Error</div>
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="closeModal"> OK </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="result.reportFileName" color="primary" outlined @click="downloadReport"> Download CSV </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-progress-circular
      v-if="!isLoaded"
      class="progress-bar"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import eventService, { EventServiceEnum } from '@/enums/event-service.enum';
import { between, required } from '@core/utils/validation';
import {
  ChargebackOutcomeRefundStatus,
  ChargebackOutcomeStatus
} from '@/enums/chargeback-status.enum';
import store from '@/store';
import handleError from '@/plugins/handle-error';
import { mapState } from 'vuex';
import Vue from 'vue';

export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler (val) {
        this.massUpdateEventsStatusModal = val;
      }
    },
  },
  computed: {
    ...mapState({
      isLoaded: state => state.chargebackEvent.isLoaded
    })
  },
  setup(data, ctx) {
    const serviceOptions = eventService;
    const eventServiceEnum = EventServiceEnum;
    const step = ref(1);
    const massUpdateEventsStatusModal = ref(false);
    const eventRefunded = ref(null);
    const eventStatus = ref(null);
    const chargebackOutcomeStatusOptions = ChargebackOutcomeStatus;
    const chargebackOutcomeRefundStatusOptions = ChargebackOutcomeRefundStatus;
    const valid = ref(false);
    const result = ref({
      countMasterCard: 1,
      countVisaCDRN: 1,
      countErrors: 1,
    });
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const back = () => {
      step.value -= 1;
    };

    const closeModal = () => {
      ctx.emit('closeBulkUpdateEventsStatusDialogDialog');
      step.value = 1;
      eventRefunded.value = null;
      eventStatus.value = null;
    };

    const validateStatus = () => {
      const isFormValid = form.value.validate();

      if (!isFormValid) {
        return;
      }

      step.value = 2;
    };

    const updateEventsStatus = () => {
      store
        .dispatch('chargebackEvent/bulkUpdateEventsStatus', {
          ids: data.events.map(item => item.id),
          status: {
            status: eventStatus.value,
            refunded: eventRefunded.value,
          }
        })
        .then((data) => {
          result.value = data;
          step.value = 3;
        })
        .catch(error => {
          handleError(error);
          closeModal();
        });
    };

    const downloadReport = () => {
      store
        .dispatch('chargebackEvent/downloadReport', result.value.reportFileName)
        .then(() => {
          closeModal();
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            text: 'Cannot download report',
          });
          closeModal();
        });
    };

    return {
      valid,
      form,
      validate,
      closeModal,
      step,
      massUpdateEventsStatusModal,
      eventServiceEnum,
      serviceOptions,
      eventRefunded,
      eventStatus,
      chargebackOutcomeStatusOptions,
      chargebackOutcomeRefundStatusOptions,
      updateEventsStatus,
      validateStatus,
      downloadReport,
      back,
      result,
      validators: { required, between },
    };
  },
};
</script>

<style lang="scss">
.progress-bar {
  margin: 0 auto;
  display: flex;
}
.card-text {
  text-align: center;
  padding-top: 20px !important;
  padding-bottom: 20px;
  color: rgba(231, 227, 252, 0.87) !important;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}
</style>
