const defaultTtl = 21600000; //6 hours

export const saveItemWithTtl = (key, value, ttl) => {
  if (!ttl) {
    ttl = defaultTtl;
  }

  const now = new Date();
  const itemWithTtl = { ...value, ttl: now.getTime() + ttl };

  localStorage.setItem(key, JSON.stringify(itemWithTtl));
};

export const getItemWithTtl = (key) => {
  let item = localStorage.getItem(key);

  if (!item) {
    return null;
  }

  item = JSON.parse(item);

  const now = new Date();
  if (item.ttl && now.getTime() > item.ttl) {
    localStorage.removeItem(key);
    return null;
  }

  return item;
};
