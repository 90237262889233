<template>
  <v-dialog v-model="generateFakeEventDialog" max-width="550" persistent>
    <v-form ref="form" v-model="valid" @submit.prevent="generateFakeEvents">
      <v-card class="generate-fake-event-modal">
        <v-card-title>Generate fake events</v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-autocomplete
                class="chargeback-event-website"
                v-model="fakeEvents.websiteId"
                :rules="[validators.required]"
                placeholder="Website name"
                :items="formattedWebsites"
                item-value="id"
                item-text="name"
                label="Website name"
                hide-details="auto"
                dense
                outlined
                clearable
              ></v-autocomplete>
            </div>
            <div class="col-12">
              <v-select
                class="chargeback-event-service"
                v-model="fakeEvents.service"
                :rules="[validators.required]"
                placeholder="Select Service"
                :items="serviceOptions"
                item-text="title"
                item-value="value"
                label="Select Service"
                hide-details="auto"
                outlined
                dense
                clearable
              ></v-select>
            </div>
            <div v-if="fakeEvents.service === eventServiceEnum.MASTERCARD_ALERT" class="col-12">
              <v-select
                class="chargeback-event-type"
                v-model="fakeEvents.eventType"
                placeholder="Select Event type"
                :items="eventTypeMasterCard"
                item-text="title"
                item-value="value"
                label="Select Event type"
                hide-details="auto"
                outlined
                dense
                clearable
              ></v-select>
            </div>
            <div v-if="fakeEvents.service === eventServiceEnum.VISA_CDRN" class="col-12">
              <v-select
                class="chargeback-event-type"
                v-model="fakeEvents.eventType"
                placeholder="Select Event type"
                :items="eventTypeVisaCdrn"
                item-text="title"
                item-value="value"
                label="Select Event type"
                hide-details="auto"
                outlined
                dense
                clearable
              ></v-select>
            </div>
            <div class="col-12">
              <v-text-field
                v-model="fakeEvents.countEvents"
                outlined
                dense
                :rules="[validators.required, validators.between(fakeEvents.countEvents, 1, 10)]"
                type="number"
                placeholder="Count events"
                label="Count events"
                hide-details="auto"
                class="chargeback-event_count-ethoca-events"
              >
              </v-text-field>
            </div>
            <div class="col-12">
              <v-switch
                v-model="isShowAdditionalInfo"
                :label="`Additional info`"
              ></v-switch>
            </div>
            <template v-if="isShowAdditionalInfo">
              <div class="col-12">
                <v-text-field
                  v-model="fakeEvents.amount"
                  outlined
                  dense
                  type="number"
                  placeholder="Amount"
                  label="Amount"
                  hide-details="auto"
                  class="chargeback-event_amount"
                >
                </v-text-field>
              </div>
              <div class="col-12">
                <v-text-field
                  v-model="fakeEvents.authCode"
                  outlined
                  dense
                  placeholder="Auth code"
                  label="Auth code"
                  hide-details="auto"
                  class="chargeback-event_auth-code"
                >
                </v-text-field>
              </div>
              <div class="col-12">
                <v-select
                  class="chargeback-event_card-type"
                  v-model="fakeEvents.cardType"
                  placeholder="Select Card type"
                  :rules="[validators.required]"
                  :items="cardType"
                  item-text="title"
                  item-value="value"
                  label="Select Card type"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                ></v-select>
              </div>
              <div class="col-12">
                <v-text-field
                  v-model="fakeEvents.cardBin"
                  outlined
                  dense
                  :rules="[validators.lengthValidator(fakeEvents.cardBin, 6)]"
                  v-mask="'######'"
                  placeholder="Card bin"
                  label="Card bin"
                  hide-details="auto"
                  class="chargeback-event_card-bin"
                >
                </v-text-field>
              </div>
              <div class="col-12">
                <v-text-field
                  v-model="fakeEvents.cardLast4"
                  outlined
                  dense
                  placeholder="Card last 4"
                  :rules="[validators.lengthValidator(fakeEvents.cardLast4, 4)]"
                  v-mask="'####'"
                  label="Card last 4"
                  hide-details="auto"
                  class="chargeback-event_card-last4"
                >
                </v-text-field>
              </div>
              <div id="transaction-date" class="col-12">
                <v-menu
                  v-model="isShowTransactionDatePickerDialog"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fakeEvents.transactionDate"
                      label="Transaction date"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fakeEvents.transactionDate"
                    @input="isShowTransactionDatePickerDialog = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-12">
                <v-menu
                  v-model="isShowCreatedAtPickerDialog"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fakeEvents.createdAt"
                      label="Created at"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:progress>
                        <slot name="progress">
                          <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
                        </slot>
                      </template>
                    </v-text-field>
                  </template>

                  <v-card width="340">
                    <v-card-text class="px-0 py-0">
                      <v-tabs fixed-tabs v-model="createdAtActiveTab">
                        <v-tab key="calendar">
                          <slot name="dateIcon">
                            <v-icon>{{ icons.mdiCalendar }}</v-icon>
                          </slot>
                        </v-tab>
                        <v-tab key="timer" :disabled="createdAtSelected()">
                          <slot name="timeIcon">
                            <v-icon>{{ icons.mdiTimer }}</v-icon>
                          </slot>
                        </v-tab>
                        <v-tab-item key="calendar">
                          <v-date-picker v-model="createdAtDate" @input="showCreatedAtTimePicker" full-width></v-date-picker>
                        </v-tab-item>
                        <v-tab-item key="timer">
                          <v-time-picker
                            ref="timer"
                            v-model="createdAtTime"
                            format="24hr"
                            full-width
                          ></v-time-picker>
                        </v-tab-item>
                      </v-tabs>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <slot name="actions" :parent="this">
                        <v-btn color="grey lighten-1" text @click.native="clearCreatedAtHandler">CLEAR</v-btn>
                        <v-btn color="green darken-1" text @click="okCreatedAtHandler">OK</v-btn>
                      </slot>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </template>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" type="submit"> Generate </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="closeModal"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import store from '@/store';
import { ref } from '@vue/composition-api';
import handleError from '@/plugins/handle-error';
import eventService, { EventServiceEnum } from '@/enums/event-service.enum';
import { between, lengthValidator, required } from '@core/utils/validation';
import EventTypeEnum, {
  EventType,
  EventTypeMasterCard,
  EventTypeVisaCdrn
} from '@/enums/event-type.enum';
import Vue from 'vue';
import CardType from '@/enums/card-type.enum';
import { mdiCalendar, mdiTimer } from '@mdi/js';

export default {
  props: {
    websites: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler (val) {
        this.generateFakeEventDialog = val;
      }
    },
  },
  setup(data, ctx) {
    const eventTypeEnum = EventTypeEnum;
    const eventType = EventType;
    const eventTypeMasterCard = EventTypeMasterCard;
    const eventTypeVisaCdrn = EventTypeVisaCdrn;
    const cardType = CardType;
    const serviceOptions = eventService;

    const eventServiceEnum = EventServiceEnum;
    const valid = ref(false);
    const isShowAdditionalInfo = ref(false);
    const isShowTransactionDatePickerDialog = ref(false);
    const isShowCreatedAtPickerDialog = ref(false);
    const form = ref(null);
    const createdAtActiveTab = ref(0);
    const createdAtDate = ref('');
    const createdAtTime = ref('00:00:00');

    const validate = () => {
      form.value.validate();
    };

    const resetForm = () => {
      form.value.reset();
    };
    const closeModal = () => {
      ctx.emit('closeFakeEventsDialog');
    };
    const generateFakeEventDialog = ref(false);
    const fakeEvents = ref({
      countEvents: 0,
      eventType: EventTypeEnum.issuer_alert,
      amount: null,
      authCode: null,
      transactionDate: null,
      cardBin: null,
      cardLast4: null,
      cardType: null,
      service: null,
      websiteId: null,
      createdAt: null,
    });
    const generateFakeEvents = () => {
      const isFormValid = form.value.validate();

      if (!isFormValid) {
        return;
      }

      Vue.$confirm({
        message: 'Are you sure you want to regenerate fake events?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (!confirm) {
            return;
          }

          store
            .dispatch('chargebackEvent/generateFakeEvents', fakeEvents.value)
            .then(() => {
              resetForm();
              closeModal();
            })
            .catch(error => (handleError(error)));
        },
      });
    };
    const okCreatedAtHandler = () => {
      fakeEvents.value.createdAt = selectedCreatedAt();
      resetCreatedAtPicker();
    };
    const clearCreatedAtHandler = () => {
      resetCreatedAtPicker();
      createdAtDate.value = '';
      createdAtTime.value = '00:00:00';
      fakeEvents.value.createdAt = null;
    };
    const resetCreatedAtPicker = () => {
      isShowCreatedAtPickerDialog.value = false;
      createdAtActiveTab.value = 0;
      if (ctx.refs.timer) {
        ctx.refs.timer.selectingHour = true;
      }
    };
    const showCreatedAtTimePicker = () => {
      createdAtActiveTab.value = 1;
    };
    const selectedCreatedAt = () => {
      if (createdAtDate.value && createdAtTime.value) {
        let createdAtString = createdAtDate.value + ' ' + createdAtTime.value;
        if (createdAtTime.value.length === 5) {
          createdAtString += ':00';
        }
        return createdAtString;
      } else {
        return '';
      }
    };
    const createdAtSelected = () => {
      return !createdAtDate.value;
    };

    return {
      valid,
      form,
      validate,
      generateFakeEvents,
      closeModal,
      generateFakeEventDialog,
      eventTypeEnum,
      eventServiceEnum,
      serviceOptions,
      eventType,
      eventTypeMasterCard,
      eventTypeVisaCdrn,
      isShowAdditionalInfo,
      isShowTransactionDatePickerDialog,
      isShowCreatedAtPickerDialog,
      fakeEvents,
      cardType,
      validators: { required, between, lengthValidator },
      createdAtActiveTab,
      createdAtDate,
      createdAtTime,
      icons: {
        mdiCalendar,
        mdiTimer,
      },
      okCreatedAtHandler,
      clearCreatedAtHandler,
      resetCreatedAtPicker,
      showCreatedAtTimePicker,
      createdAtSelected,
    };
  },
  computed: {
    formattedWebsites() {
      return this.websites.map((website) => {
        if (website.status === 'inactive') {
          return { ...website, name: website.name + ' (inactive)' };
        }
        return website;
      });
    },
  },
};
</script>

<style lang="scss">
.pending-row {
  background-color: rgb(255 215 215 / 40%);
}
.expired-row {
  background-color: rgb(201 201 201 / 40%);
}
.event-date-picker {
  > .v-card__actions {
    display: block !important;
    > div.row:last-child {
      text-align: end;
    }
  }
}
#transaction-date {
  .v-text-field__details {
    min-height: 0;
  }
  .v-messages {
    min-height: 0;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
}
@import '@core/preset/preset/apps/user.scss';
</style>
