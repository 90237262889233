import store from '@/store';
import { computed, ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import eventService, { EventServiceEnum } from '@/enums/event-service.enum';
import CardType from '@/enums/card-type.enum';
import {
  ChargebackStatus,
  ChargebackStatusEnum,
  ChargebackOutcomeStatus,
  ChargebackOutcomeRefundStatus,
} from '@/enums/chargeback-status.enum';
import { getProfile } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';
import moment from 'moment';
import EventTypeEnum, {
  EventType,
  EventTypeMasterCard,
} from '@/enums/event-type.enum';
import RolesEnum from '@/enums/roles.enum';
import { saveItemWithTtl } from '@core/utils/local-storage-with-ttl';
import { debounce } from 'vue2-leaflet';

export default function useChargebackEventsList() {
  const watchDebounce = debounce(() => {
    options.value.page = 1;
    fetchEvents();
  }, 1000);

  const isAdmin = getProfile().role === Roles.ADMIN;

  /**
   * Data options
   */
  const refunded = ref(null);
  const eventListTable = ref([]);
  const tableColumns = [
    { text: 'ID', order: 1, value: 'eventId', sortable: false, byDefaultShow: true },
    { text: 'Event ID', order: 2, value: 'alertId', sortable: false, byDefaultShow: true },
    {
      text: 'CreatedAt',
      order: 3,
      value: 'createdAt',
      tooltip: 'Event added to Merchanto platform',
      byDefaultShow: false,
    },
    {
      text: 'Event Time',
      order: 4,
      value: 'alertTimestamp',
      tooltip: 'Time and date when event occurred',
      byDefaultShow: true,
    },
    {
      text: 'Expired in ',
      order: 6,
      value: 'expiredIn',
      sortable: false,
      tooltip: 'Time left to provide outcome',
      byDefaultShow: false,
    },
    { text: 'Website', order: 7, value: 'websiteName', sortable: false, byDefaultShow: true },
    { text: 'Details', order: 8, value: 'details', sortable: false, byDefaultShow: true },
    { text: 'Auth code', order: 9, value: 'authCode', sortable: false, byDefaultShow: false },
    { text: 'Transaction amount', order: 10, value: 'transactionAmount', sortable: false, byDefaultShow: true },
    { text: 'Transaction Date', order: 11, value: 'transactionDate', sortable: false, byDefaultShow: true },
    { text: 'Status', order: 12, value: 'status', sortable: false, byDefaultShow: true },
    { text: 'Card Type', order: 13, value: 'cardType', sortable: false, byDefaultShow: true },
    { text: 'Card number', order: 14, value: 'cardNumber', sortable: false, byDefaultShow: true },
    { text: 'ARN', order: 15, value: 'arn', sortable: false, byDefaultShow: true },
    { text: 'Service', order: 16, value: 'service', sortable: false, byDefaultShow: true },
    {
      text: 'Actions',
      order: 16,
      value: 'actions',
      align: 'center',
      sortable: false,
      byDefaultShow: true,
    },
  ];

  if (isAdmin) {
    tableColumns.splice(3, 0, {
      text: 'Account name',
      order: 5,
      value: 'accountName',
      sortable: false,
      byDefaultShow: true,
    });
  }

  if (store.getters['chargebackEvent/getSelectedEventTableHeaders'].length === 0) {
    const defaultTableColumns = tableColumns.filter(column => {
      return column.byDefaultShow !== false;
    });
    store.commit('chargebackEvent/selectedEventTableHeaders', defaultTableColumns);
  }

  const searchQuery = ref('');
  const totalEventListTable = ref(0);
  const loading = ref(false);
  const websiteFilter = ref(null);
  const newEventStatus = ref(null);
  const companyFilter = ref(null);
  const serviceFilter = ref(null);
  const statusFilter = ref(null);
  const cardTypeFilter = ref(null);
  const eventDateFilter = ref(null);
  const eventDateModal = ref(null);
  const merchants = ref([]);
  const websites = ref([]);
  const eventTotalLocal = ref([]);
  const outcomeDialog = ref(false);
  const uploadFileDialog = ref(false);
  const generateFakeEventDialog = ref(false);
  const bulkUpdateEventsStatusDialog = ref(false);
  const uploadedFile = ref(null);
  const uploadedFileProcessing = ref(false);
  const uploadedFileProcessingResult = ref(null);
  const uploadedFileProcessingError = ref(null);
  const updatedStatusResponse = ref(null);
  const isUpdatedStatusReceived = ref(false);
  const isMassSelectedEvents = ref(false);
  const selectedEvents = ref([]);
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  });

  const defaultOptions = {
    'page': 1,
    'itemsPerPage': 50,
    'sortBy': ['createdAt'],
    'sortDesc': [true],
    'value': {
      'page': 1,
      'itemsPerPage': 50,
      'sortBy': ['createdAt'],
      'sortDesc': [true],

    }
  };

  const newDate = new Date();
  const presetsForEventDatePicker = [
    {
      title: '3 Days',
      value: [moment(newDate).subtract(2, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: '7 Days',
      value: [moment(newDate).subtract(6, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: '30 Days',
      value: [moment(new Date()).subtract(29, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'This Month',
      value: [moment(newDate).startOf('month').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'Last Month',
      value: [
        moment(newDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      title: 'Last 3 Months',
      value: [
        moment(newDate).subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
  ];

  /**
   * fetchMerchants
   */
  const fetchMerchants = () => {
    store
      .dispatch('user/fetchAll', {
        page: 1,
        role: RolesEnum.MERCHANT,
        itemsPerPage: '-1',
      })
      .then(response => {
        merchants.value = response.data.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Merchants',
          text: 'Cannot fetch merchants',
        });
      });
  };

  /**
   * fetchWebsites
   */
  const fetchWebsites = () => {
    store
      .dispatch('website/fetchWebsites')
      .then(response => {
        websites.value = response.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Websites',
          text: 'Cannot fetch websites',
        });
      });
  };

  /**
   * exportEvents
   */
  const exportEvents = () => {
    store
      .dispatch('chargebackEvent/exportEvents', {
        q: searchQuery.value,
        cardType: cardTypeFilter.value,
        service: serviceFilter.value,
        websiteIds: websiteFilter.value,
        companyIds: companyFilter.value,
        chargebackEventDate: eventDateFilter.value,
        status: statusFilter.value,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
        page: options.value.page,
        itemsPerPage: '-1',
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Events',
          text: 'Cannot export events',
        });
      });
  };

  /**
   * updateStatus
   * @param eventId
   * @param status
   * @param refundedStatus
   */
  const updateStatus = (eventId, status, refundedStatus) => {
    if (!refundedStatus || !status) {
      Vue.notify({
        type: 'error',
        title: 'Events',
        text: 'Cannot update event status. Please provide all data!',
      });
      return;
    }
    outcomeDialog.value = true;
    store
      .dispatch('chargebackEvent/patchStatus', { id: eventId, status: status, refunded: refundedStatus })
      .then(() => {
        isUpdatedStatusReceived.value = true;
        updatedStatusResponse.value = 'Outcome has been accepted';
        eventListTable.value[eventListTable.value.findIndex(event => event.id === eventId)].status = status;
      })
      .catch(error => {
        if (error.response.status === 422) {
          isUpdatedStatusReceived.value = true;
          updatedStatusResponse.value = error.response.data.message;
        } else {
          isUpdatedStatusReceived.value = true;
          updatedStatusResponse.value = 'Error has been occurred. Please try again or contact your platform manager';
        }
      });
    newEventStatus.value = null;
    refunded.value = null;
  };

  /**
   * closeOutcomeDialog
   */
  const closeOutcomeDialog = () => {
    outcomeDialog.value = false;
    isUpdatedStatusReceived.value = false;
    updatedStatusResponse.value = null;
  };

  /**
   * fetchEvents
   */
  const fetchEvents = () => {
    const filters = {
      q: searchQuery.value,
      cardType: cardTypeFilter.value,
      service: serviceFilter.value,
      websiteIds: websiteFilter.value,
      companyIds: companyFilter.value,
      chargebackEventDate: eventDateFilter.value,
      status: statusFilter.value,
      sortBy: options.value.sortBy[0],
      sortDesc: options.value.sortDesc[0],
      page: options.value.page,
      itemsPerPage: options.value.itemsPerPage,
    };

    if (isMassSelectedEvents.value) {
      filters.page = 1;
      filters.itemsPerPage = '-1';
    }

    loading.value = true;

    store
      .dispatch('chargebackEvent/fetchEvents', {
        ...filters,
      })
      .then(response => {
        eventListTable.value = response.data.data;
        totalEventListTable.value = response.data.totalCount;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Events',
          text: 'Cannot fetch events',
        });
      })
      .finally(() => {
        loading.value = false;
      });
  };

  /**
   * resolveEventStatusVariant
   * @param status
   * @returns {string}
   */
  const resolveEventStatusVariant = (status) => {
    switch (status) {
      case chargebackStatusEnum.PENDING:
      case chargebackStatusEnum.EXPIRED:
      case chargebackStatusEnum.DECLINED:
        return 'error';
      case chargebackStatusEnum.DELETED:
        return '';
      default:
        return 'success';
    }
  };

  const canChangeStatus = (chargeback) => {
    const allowedChargebackServices = [
      eventServiceEnum.MASTERCARD_ALERT,
      eventServiceEnum.VISA_CDRN,
    ];

    const allowedCDRNChargebackTypes = [
      eventTypeEnum.dispute,
      eventTypeEnum.cancel,
    ];

    const forbiddenChargebackStatuses = [
      chargebackStatusEnum.DELETED,
      chargebackStatusEnum.EXPIRED,
    ];

    if (!allowedChargebackServices.includes(chargeback.service) ||
      forbiddenChargebackStatuses.includes(chargeback.status) ||
      chargeback.outcome
    ) {
      return false;
    }

    return !(chargeback.service === eventServiceEnum.VISA_CDRN &&
      !allowedCDRNChargebackTypes.includes(chargeback.alertType));
  };

  watch([options],() => {
      fetchEvents();
    },
  );

  watch(
    [companyFilter, websiteFilter, serviceFilter, cardTypeFilter, statusFilter, eventDateFilter],
    () => {
      options.value.page = 1;
      fetchEvents();
    },
  );

  watch(
    [searchQuery],
    () => {
      if ((searchQuery.value && searchQuery.value.length >= 4) || searchQuery.value === null) {
        watchDebounce();
      }
    },
  );

  const searchKeyupDelete = (event) => {
    if (event.target.value.length === 0) {
      watchDebounce();
    }
  };

  const headersSelected = computed({
    get() {
      return store.getters['chargebackEvent/getSelectedEventTableHeaders'];
    },
    set(columns) {
      store.commit(
        'chargebackEvent/selectedEventTableHeaders',
        columns.sort((column1, column2) => {
          return column1.order > column2.order ? 1 : -1;
        }),
      );
    },
  });

  if (isAdmin) {
    fetchMerchants();
  }
  fetchWebsites();

  watch(uploadedFile, () => {
    if (!uploadedFile.value) {
      return;
    }

    uploadedFileProcessing.value = true;

    let formData = new FormData();
    formData.append('file', uploadedFile.value);
    store
      .dispatch('chargebackEvent/uploadFile', formData)
      .then(response => {
        uploadedFileProcessingResult.value = `Uploading process finished in ${response.data.timeSpent} minutes.`;
        uploadedFileProcessingResult.value += `Added ${response.data.chargebackEventsAddedCount} events. Skipped ${response.data.chargebackEventsSkippedCount} events.`;
        fetchEvents();
      })
      .catch(() => {
        uploadedFileProcessingError.value = 'Can not parse file and add events.';
      })
      .finally(() => {
        uploadedFileProcessing.value = false;
        uploadedFile.value = null;

        setTimeout(() => {
          uploadedFileProcessingResult.value = null;
          uploadedFileProcessingError.value = null;
        }, 5000);
      });
  });

  const resetFilters = () => {
    companyFilter.value = null;
    websiteFilter.value = null;
    serviceFilter.value = null;
    cardTypeFilter.value = null;
    statusFilter.value = null;
    eventDateFilter.value = null;
    searchQuery.value = null;
    saveFilters();
  };

  const saveFilters = () => {
    saveItemWithTtl('event-filter', {
      companyFilter: companyFilter.value,
      websiteFilter: websiteFilter.value,
      serviceFilter: serviceFilter.value,
      cardTypeFilter: cardTypeFilter.value,
      statusFilter: statusFilter.value,
      eventDateFilter: eventDateFilter.value,
      searchQuery: searchQuery.value,
    });
  };

  /**
   * Enums
   */
  const serviceOptions = eventService;
  const eventServiceEnum = EventServiceEnum;
  const cardTypeOptions = CardType;
  const eventTypeEnum = EventTypeEnum;
  const chargebackStatusOptions = ChargebackStatus;
  const chargebackOutcomeStatusOptions = ChargebackOutcomeStatus;
  const chargebackOutcomeRefundStatusOptions = ChargebackOutcomeRefundStatus;
  const chargebackStatusEnum = ChargebackStatusEnum;
  const eventType = EventType;
  const eventTypeMasterCard = EventTypeMasterCard;

  return {
    isAdmin,
    headersSelected,
    searchQuery,
    companyFilter,
    websiteFilter,
    serviceFilter,
    cardTypeFilter,
    statusFilter,
    eventDateFilter,
    eventDateModal,
    merchants,
    websites,
    eventListTable,
    tableColumns,
    totalEventListTable,
    loading,
    options,
    eventTotalLocal,
    newEventStatus,
    fetchEvents,
    resolveEventStatusVariant,
    outcomeDialog,
    updatedStatusResponse,
    isUpdatedStatusReceived,
    updateStatus,
    closeOutcomeDialog,
    exportEvents,
    serviceOptions,
    cardTypeOptions,
    eventTypeMasterCard,
    chargebackStatusOptions,
    chargebackOutcomeStatusOptions,
    chargebackOutcomeRefundStatusOptions,
    eventServiceEnum,
    eventTypeEnum,
    eventType,
    refunded,
    presetsForEventDatePicker,
    generateFakeEventDialog,
    uploadFileDialog,
    uploadedFile,
    uploadedFileProcessing,
    uploadedFileProcessingResult,
    uploadedFileProcessingError,
    defaultOptions,
    canChangeStatus,
    isMassSelectedEvents,
    chargebackStatusEnum,
    resetFilters,
    selectedEvents,
    bulkUpdateEventsStatusDialog,
    saveFilters,
    searchKeyupDelete,
  };
}
