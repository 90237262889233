export const ChargebackStatus = [
  { title: 'Resolved', value: 'resolved' },
  { title: 'Previously Resolved', value: 'previously_resolved' },
  { title: 'Not Found', value: 'notfound' },
  { title: 'Declined', value: 'declined' },
  { title: 'Pending', value: 'pending' },
  { title: 'Deleted', value: 'deleted' },
  { title: 'Expired', value: 'expired' },
];

export const ChargebackStatusEnum = {
  RESOLVED: 'resolved',
  PENDING: 'pending',
  DECLINED: 'declined',
  DELETED: 'deleted',
  PREVIOUSLY_RESOLVED: 'previously_resolved',
  NOT_FOUND: 'notfound',
  EXPIRED: 'expired',
};

export const ChargebackOutcomeStatus = [
  { title: 'Resolved', value: 'resolved' },
  { title: 'Previously Resolved', value: 'previously_resolved' },
  { title: 'Not Found', value: 'notfound' },
  { title: 'Declined', value: 'declined' },
];

export const ChargebackOutcomeStatusEnum = {
  RESOLVED: 'resolved',
  PREVIOUSLY_RESOLVED: 'previously_resolved',
  NOT_FOUND: 'notfound',
  DECLINED: 'declined',
};

export const ChargebackOutcomeRefundStatus = [
  { title: 'Refunded', value: 'refunded' },
  { title: 'Not Refunded', value: 'not refunded' },
  { title: 'Not Settled', value: 'not settled' },
];

export const ChargebackOutcomeRefundStatusEnum = {
  REFUNDED: 'refunded',
  NOT_REFUNDED: 'not refunded',
  NOT_SETTLED: 'not settled',
};
