const EventTypeEnum = {
  issuer_alert: 'issuer_alert',
  customerdispute_alert: 'customerdispute_alert',
  cancel: 'cancel',
  fraud_notice: 'fraud_notice',
  dispute: 'dispute',
};

export const EventType = [
  { title: 'Confirmed Fraud Alert', value: 'issuer_alert' },
  { title: 'Customer Dispute Alert', value: 'customerdispute_alert' },
  { title: 'Cancel', value: 'cancel' },
  { title: 'Fraud Notice', value: 'fraud_notice' },
  { title: 'Dispute', value: 'dispute' },
];

export const EventTypeMasterCard = [
  { title: 'Confirmed Fraud Alert', value: 'issuer_alert' },
  { title: 'Customer Dispute Alert', value: 'customerdispute_alert' },
];

export const EventTypeVisaCdrn = [
  { title: 'Cancel', value: 'cancel' },
  { title: 'Fraud Notice', value: 'fraud_notice' },
  { title: 'Dispute', value: 'dispute' },
];

export const EventTypeClearName = {
  issuer_alert: 'Confirmed Fraud Alert',
  customerdispute_alert: 'Customer Dispute Alert',
  cancel: 'Cancel',
  fraud_notice: 'Fraud Notice',
  dispute: 'Dispute',
};

export default EventTypeEnum;
